import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { selectSubscribedApps } from 'src/app/applications/selectors/app.selectors';
import { AppState } from '../../reducers';
import { APP_NAMES, TrialState } from '../constants';
import { AppChannelSubscription } from '../model/AppChannelSubscription';
import { AdvancedAccountingService } from '../../advanced-accounting/advanced-accounting.service';
import { ConfigurationName } from '../../configuration/configuration.service';
import { AppService } from '../../applications/services/app.service';
import { InstalledApp } from '../model/InstalledApp';

@Injectable()
export class ApplicationsHelperService {
  permittedInstalledApps$ = new BehaviorSubject<AppChannelSubscription[]>(null);

  allInstalledApps$ = new BehaviorSubject<InstalledApp[]>(null);

  constructor(
    private store: Store<AppState>,
    private advanceAccountingService: AdvancedAccountingService,
    private appService: AppService,
  ) {}

  get permittedInstalledApps(): AppChannelSubscription[] {
    return this.permittedInstalledApps$.getValue();
  }

  get allInstalledApps(): InstalledApp[] {
    return this.allInstalledApps$.getValue();
  }

  async getSubscribedApps(): Promise<AppChannelSubscription[]> {
    const subscribedApps = await firstValueFrom(
      this.store.pipe(select(selectSubscribedApps), take(1)),
    );
    return subscribedApps.filter((subscribedApp) => {
      const matchingAppName = Object.values(APP_NAMES).find(
        (appName) => appName.toLowerCase() === subscribedApp.name.toLowerCase(),
      );

      if (matchingAppName) {
        // eslint-disable-next-line no-param-reassign
        subscribedApp.name = matchingAppName;
        return true;
      }

      return false;
    });
  }

  async fetchApps(): Promise<void> {
    await Promise.all([
      this.fetchPermittedInstalledApps(),
      this.fetchAllInstalledApps(),
    ]);
  }

  async fetchPermittedInstalledApps(): Promise<void> {
    if (this.permittedInstalledApps) {
      return;
    }
    await firstValueFrom(
      this.appService.getSubscribedAppsApiCall().pipe(
        tap((subscribedApps) => {
          this.permittedInstalledApps$.next(subscribedApps);
        }),
      ),
    );
  }

  async fetchAllInstalledApps(): Promise<void> {
    if (this.allInstalledApps) {
      return;
    }
    await firstValueFrom(
      this.appService.getInstalledApps().pipe(
        tap((installedApps) => {
          this.allInstalledApps$.next(installedApps);
        }),
      ),
    );
  }

  async isAppInstalled(appName: APP_NAMES): Promise<boolean> {
    return !!this.allInstalledApps.find(
      (app) => app.name.toLowerCase() === appName.toLowerCase(),
    );
  }

  async isPermittedAppInstalled(appName: APP_NAMES): Promise<boolean> {
    if (appName === APP_NAMES.EXPENSES)
      return this.isAccountingAppInstalled(this.permittedInstalledApps);

    return !!this.permittedInstalledApps.find(
      (app) => app.name.toLowerCase() === appName.toLowerCase(),
    );
  }

  async isAccountingAppInstalled(
    subscribedApps: AppChannelSubscription[],
  ): Promise<boolean> {
    const isExpensesInstalled = !!subscribedApps.find(
      (sub) => sub.name.toLowerCase() === APP_NAMES.EXPENSES.toLowerCase(),
    );

    if (isExpensesInstalled) return true;

    const accountingStatus =
      await this.checkAccountingAppStatus(subscribedApps);

    return (
      (accountingStatus.isAccountingAppInstalled &&
        !accountingStatus.isAccountingAppTrial) ||
      (accountingStatus.isAccountingAppTrial &&
        !accountingStatus.isTrialNotStarted)
    );
  }

  isAdvAccountingAppInstalled(
    subscribedApps: AppChannelSubscription[],
  ): boolean {
    return !!subscribedApps.find(
      (sub) => sub.name.toLowerCase() === APP_NAMES.ACCOUNTING.toLowerCase(),
    );
  }

  isPromotionAppInstalled(subscribedApps: AppChannelSubscription[]): boolean {
    return !!subscribedApps.find(
      (sub) =>
        sub.name.toLowerCase() === APP_NAMES.PROMOTION_MANAGMENET.toLowerCase(),
    );
  }

  isPosAppInstalled(subscribedApps: AppChannelSubscription[]): boolean {
    return !!subscribedApps.find(
      (sub) => sub.name.toLowerCase() === APP_NAMES.INTERNAL_POS.toLowerCase(),
    );
  }

  checkAccountingAppStatus = async (
    subscribedApps: AppChannelSubscription[],
  ): Promise<{
    isAccountingAppInstalled: boolean;
    isAccountingAppTrial: boolean;
    isTrialNotStarted: boolean;
    isAccountingTrialEnd: boolean;
  }> => {
    const accountingApp = subscribedApps.find(
      (sub) => sub.name.toLowerCase() === APP_NAMES.ACCOUNTING.toLowerCase(),
    );

    const configuration = await firstValueFrom(
      this.advanceAccountingService.getSetting(ConfigurationName.SETUP),
    );

    const isAccountingAppInstalled = !!accountingApp;
    const isAccountingAppTrial =
      isAccountingAppInstalled && accountingApp.trialStatus !== TrialState.PAID;
    const isTrialNotStarted =
      isAccountingAppInstalled &&
      isAccountingAppTrial &&
      !configuration?.value?.bankAndOpeningBalance;
    const isAccountingTrialEnd =
      accountingApp?.trialStatus === TrialState.EXPIRED;

    return {
      isAccountingAppInstalled,
      isAccountingAppTrial,
      isTrialNotStarted,
      isAccountingTrialEnd,
    };
  };
}
