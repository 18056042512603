import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { PermissionHelperService } from '../../shared/services/permission.helper.service';
import { FeatureFlagService } from '../../shared/services/types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../shared/constants/feature-flag.constants';
import { RoutePermissionsService } from '../../shared/services/route-permissions.service';

@Injectable()
export class ActiveGuardService implements CanActivate {
  isPermissionsV2Enabled$: BehaviorSubject<boolean | undefined> =
    new BehaviorSubject(undefined);

  constructor(
    private router: Router,
    private perSrv: PermissionHelperService,
    private featureFlagService: FeatureFlagService,
    private routePermissionsService: RoutePermissionsService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.getPermissionsV2Flag().pipe(
      switchMap((isPermissionV2Enabled) => {
        if (!isPermissionV2Enabled) {
          return this.perSrv.getLandingUrl(state.url);
        }
        return this.routePermissionsService.handleUrlPermissions(state.url);
      }),
      map((url) => {
        if (typeof url === 'boolean') {
          if (!url) {
            this.appendReturnUrlInQueryParams(state);
          }
          return !!url;
        }
        return this.router.createUrlTree([url]);
      }),
    );
  }

  appendReturnUrlInQueryParams(state: RouterStateSnapshot): void {
    const excludedRoutes = ['/getting-started'];
    if (!excludedRoutes.includes(state.url) && state.url !== '/') {
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
      });
    } else {
      this.router.navigate(['login']);
    }
  }

  getPermissionsV2Flag(): Observable<boolean> {
    // eslint-disable-next-line eqeqeq
    if (this.isPermissionsV2Enabled$.value == undefined) {
      return this.featureFlagService
        .isEnabled(FeatureFlagEnum.PermissionsV2)
        .pipe(
          tap((permissionsV2) => {
            this.isPermissionsV2Enabled$.next(permissionsV2);
          }),
        );
    }
    return of(this.isPermissionsV2Enabled$.value);
  }
}
